<style lang="scss">
#account_cursors {
  .flat_picker_std {
    width: 100px;
  }
  .leave_cursor {
    input {
      padding: 0;

      &[type="number"] {
        width: 70px;
        text-align: center;
      }
    }
    button {
      width: 50px;
    }
  }
}
</style>

<template>
  <modal id="account_cursors" @close="close" class="leave_modal" size="xl">
    <template #header>
      Compteurs de {{ recup ? 'récupération' : 'congés' }}
      <div v-if="!recup" class="ml-auto d-flex align-items-center">
        <input type="file" ref="file" class="btn btn-sm" />
        <FlatPickr class="flat_picker_std form-control form-control-sm mx-1" :config="dateConfig"
          v-model="leavesToDate" />
        <button type="button" class="btn btn-primary btn-sm" @click="upload">Importer</button>
      </div>
      <button class="btn close" :class="{ 'ml-2': !recup }" @click="close">&times;</button>
    </template>

    <div v-if="recup">
      <AccountCursorRecup v-for="cursor in cursors" :key="cursor.user_id" :cursor="cursor" />
    </div>
    <div v-else>
      <AccountCursor v-for="cursor in cursors" :key="cursor.user_id" :cursor="cursor" />
    </div>
  </modal>
</template>

<script>
import { mapGetters } from "vuex"
import dayjs from 'dayjs';
import FlatPickr from "vue-flatpickr-component"
import { French } from "flatpickr/dist/l10n/fr.js"
import * as Config from "@/config/constants";
import Modal from "@/views/modal/Modal.vue"
import AccountCursor from "./AccountCursor.vue"
import AccountCursorRecup from "./AccountCursorRecup.vue"

export default {
  name: "AccountCursors",
  components: {
    Modal,
    AccountCursor,
    AccountCursorRecup,
    FlatPickr,
  },
  props: {
    recup: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      dateConfig: { locale: French },
      leavesToDate: dayjs().subtract(1, 'month').endOf('month').format(Config.DATE_FORMAT),
      cursorsData: [],
    }
  },
  computed: {
    ...mapGetters({
      resources: "Resource/list/resourcesAll",
    }),
    cursors() {
      return this.cursorsData
        .map((cursor) => {
          const user = this.resources.find(
            (resource) => resource.id == cursor.user_id
          )
          cursor.user_name = `${user.lastname} ${user.firstname}`
          return cursor
        })
        .sort((a, b) => {
          if (a.user_name.toLowerCase() > b.user_name.toLowerCase()) return 1
          if (a.user_name.toLowerCase() < b.user_name.toLowerCase()) return -1
          return 0
        })
    },
  },
  methods: {
    close() {
      this.$emit("close")
    },
    upload() {
      const $input = this.$refs.file
      if (!$input.value) return
      if (!this.leavesToDate) return
      const payload = {
        file: $input.files[0],
        leavesToDate: this.leavesToDate,
      }
      this.$store.dispatch("Dashboard/list/postLeaveCursors", payload).then(cursorsData => {
        this.cursorsData = cursorsData
        $input.value = null
      })
    }
  },
  mounted() {
    this.$store.dispatch("Dashboard/list/getLeaveCursors").then(cursorsData => {
      this.cursorsData = cursorsData
    })
  },
}
</script>
