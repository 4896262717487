<style lang="scss">
@import "~tippy.js/dist/tippy.css";
</style>

<template>
  <label class="time_entry custom-control custom-checkbox">
    <input
      type="checkbox"
      class="custom-control-input"
      :value="value"
      v-model="selected"
    />
    <div class="custom-control-label">
      <div>{{ showDate(value.spent_on) }} - {{ showDays(value.hours) }}j</div>
      <div v-if="showSub" class="sub">{{ value.subject }}</div>
    </div>
    <icon-comment
      v-if="hasComments"
      ref="comment"
      :data-tippy-content="value.comments"
    />
  </label>
</template>

<script>
import dayjs from "dayjs";
import tippy from "tippy.js";
import * as Helpers from "../dashboard-helpers";
import IconComment from "@/icons/comment-alt-solid.svg";

export default {
  name: "Leave",
  model: { prop: "leaves" },
  components: { IconComment },
  props: {
    value: {},
    leaves: {},
    showSub: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    selected: {
      get() {
        return this.leaves;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    hasComments() {
      return this.value.comments && this.value.comments !== ".";
    }
  },
  methods: {
    showDays(hours) {
      return Helpers.showDays(hours);
    },
    showDate(date) {
      return dayjs(date).format("ddd D MMM");
    },
  },
  mounted() {
    if (this.hasComments) {
      tippy(this.$refs.comment);
    }
  },
};
</script>
