<template>
  <div class="d-flex align-items-center justify-content-between mb-2">
    <div class="text-truncate">{{ newCursor.user_name }}</div>
    <div class="d-flex align-items-center leave_cursor">
      reste
      <input
        class="form-control ml-1"
        type="number"
        step=".5"
        min="0"
        v-model="newCursor.leaves_recup"
        @keyup.enter="submit"
      />j de récupération au
      <flat-pickr
        class="flat_picker_std form-control mx-1"
        :config="dateConfig"
        v-model="newCursor.leaves_recup_to_date"
      />
      <button
        :class="[
          'btn', 'ml-1',
          { 'btn-primary': !submitSuccess, 'btn-success': submitSuccess },
        ]"
        @click="submit"
      >
        <span v-if="submitSuccess">&check;</span><span v-else>Ok</span>
      </button>
    </div>
  </div>
</template>

<script>
import FlatPickr from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";

export default {
  name: "AccountCursorRecup",
  components: {
    FlatPickr,
  },
  props: ["cursor"],
  data() {
    return {
      dateConfig: { locale: French },
      newCursor: {
        user_id: null,
        leaves_recup: null,
        leaves_recup_to_date: null,
      },
      submitSuccess: false,
    };
  },
  methods: {
    submit() {
      const payload = {
        userId: this.newCursor.user_id,
        user: {
          leaves_recup: this.newCursor.leaves_recup,
          leaves_recup_to_date: this.newCursor.leaves_recup_to_date,
        },
      };
      this.$store.dispatch("Resource/edit/setExtra", payload).then(() => {
        this.submitSuccess = true;
        setTimeout(() => {
          this.submitSuccess = false;
        }, 1000);
      });
    },
  },
  mounted() {
    this.newCursor = this.cursor;
  },
};
</script>
