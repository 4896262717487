import { mapGetters } from "vuex";
import dayjs from 'dayjs';
import Multiselect from "vue-multiselect";
import Modal from "@/views/modal/Modal.vue";
import LeaveModalBlock from "./LeaveModalBlock.vue";

export default {
    name: "AccountLeaveModal",
    components: {
        Modal,
        LeaveModalBlock,
        Multiselect,
    },
    props: ["leaves"],
    data() {
        return {
            filterResource: null,
            filterStartDate: dayjs().startOf('month'),
            filterableResources: [],
        };
    },
    computed: {
        // @ts-ignore
        ...mapGetters({
            resources: "Resource/list/resourcesAll",
        }),
        indexedResources() {
            return new Map(this.resources.map(res => [res.id, `${res.firstname} ${res.lastname}`]));
        },
        filteredLeaves() {
            const filteredUserId = this.filterResource?.id;
            const filterStartDate = this.filterStartDate;
            const filterEndDate = this.filterEndDate;
            return this.leaves.filter(leave => {
                if (filteredUserId && parseInt(leave.user_id) !== filteredUserId) return false;
                if (filterStartDate && dayjs(leave.spent_on).isBefore(filterStartDate)) return false;
                if (filterEndDate && dayjs(leave.spent_on).isAfter(filterEndDate)) return false;
                return true;
            });
        },
        filterEndDate() {
            return this.filterStartDate.endOf('month');
        },
        formattedPeriod() {
            const formattedYear =
                this.filterStartDate.year() !== new Date().getFullYear()
                    ? ' ' + this.filterStartDate.year() : '';
            let formattedMonth = this.filterStartDate.format('MMMM');
            formattedMonth = formattedMonth[0].toUpperCase() + formattedMonth.slice(1);
            return formattedMonth + formattedYear;
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        leavesForStatus(status) {
            return this.filteredLeaves.filter((leave) => leave.status === status);
        },
        submitted() {
            this.$store.dispatch('Dashboard/list/heartbeat');
            this.$emit('submitted');
        },
        shiftStartDate(offset) {
            this.filterStartDate = this.filterStartDate.add(offset, 'month');
        }
    },
    mounted() {
        const resources = this.indexedResources;
        this.filterableResources = this.leaves
            .map(leave => parseInt(leave.user_id)) // Getting the user_ids
            .filter((value, index, self) => self.indexOf(value) === index) // Distinct
            .map(id => ({ id, name: resources.get(id) })); // Then the related resources
    },
};
