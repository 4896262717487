<template>
  <div class="d-flex align-items-center justify-content-between mb-2">
    <div class="text-truncate">{{ newCursor.user_name }}</div>
    <div class="d-flex align-items-center leave_cursor">
      reste
      <input class="form-control ml-1" type="number" step=".1" min="0" v-model="newCursor.leaves_cpn"
        @keyup.enter="submit" />j de CP N-1,
      <input class="form-control ml-1" type="number" step=".1" min="0" v-model="newCursor.leaves_cp"
        @keyup.enter="submit" />j de CP N,
      <input class="form-control ml-1" type="number" step=".5" min="0" v-model="newCursor.leaves_rtt"
        @keyup.enter="submit" />j de RTT au
      <flat-pickr class="flat_picker_std form-control mx-1" :config="dateConfig" v-model="newCursor.leaves_to_date" />
      <button :class="[
      'btn',
      { 'btn-primary': !submitSuccess, 'btn-success': submitSuccess },
    ]" @click="submit">
        <span v-if="submitSuccess">&check;</span><span v-else>Ok</span>
      </button>
    </div>
  </div>
</template>

<script>
import FlatPickr from "vue-flatpickr-component"
import { French } from "flatpickr/dist/l10n/fr.js"

export default {
  name: "AccountCursor",
  components: {
    FlatPickr,
  },
  props: ["cursor"],
  data() {
    return {
      dateConfig: { locale: French },
      newCursor: {
        user_id: null,
        leaves_cp: null,
        leaves_cpn: null,
        leaves_rtt: null,
        leaves_to_date: null,
      },
      submitSuccess: false,
    }
  },
  methods: {
    submit() {
      const payload = {
        userId: this.newCursor.user_id,
        user: {
          leaves_cp: this.newCursor.leaves_cp,
          leaves_cpn: this.newCursor.leaves_cpn,
          leaves_rtt: this.newCursor.leaves_rtt,
          leaves_to_date: this.newCursor.leaves_to_date,
        },
      }
      this.$store.dispatch("Resource/edit/setExtra", payload).then(() => {
        this.submitSuccess = true
        setTimeout(() => {
          this.submitSuccess = false
        }, 1000)
      })
    },
    init() {
      this.newCursor = this.cursor
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    cursor: {
      deep: true,
      handler() {
        this.init()
      }
    }
  }
}
</script>
