import { mapGetters } from "vuex";
import dayjs from 'dayjs';
import FlatPickr from 'vue-flatpickr-component';
import { French } from 'flatpickr/dist/l10n/fr.js';
import { delegate as tippyDelegate } from "tippy.js";
import ModalArrow from "@/img/modal-arrow.svg";
import * as Helpers from "../dashboard-helpers";
import { DATE_FORMAT } from '@/config/constants';
import {
    ACTIVITY_CONSULTANT,
    ADMINISTRATIVE_PROJECT,
    ISSUE_LEAVE_ANCIEN,
    ISSUE_LEAVE_CP,
    ISSUE_LEAVE_RECUP,
    ISSUE_LEAVE_RTT
} from '@/config/redmine-constants';
import { TimeEntryInput } from "@/app/types/interfaces";
import { userIsAtLeastDP } from "@/app/helpers";
import Modal from "@/views/modal/Modal.vue";
import LeaveModalBlock from "./LeaveModalBlock.vue";
import ResourceSelect from "@/views/common/ResourceSelect.vue";

function getDefaultNewLeave() {
    return {
        issueId: "",
        dates: [],
        comments: "",
        startHalf: false,
        endHalf: false,
    };
}

function getDefaultErrors() {
    return {
        issueId: false,
        dates: false,
    };
}

export default {
    name: "MyLeaveModal",
    components: {
        Modal,
        LeaveModalBlock,
        FlatPickr,
        ModalArrow,
        ResourceSelect,
    },
    props: ["type"],
    data() {
        return {
            leaveTypes: [],
            dateConfig: {
                locale: French,
                mode: 'range',
                onDayCreate: this.onDayCreate,
            },
            newLeave: getDefaultNewLeave(),
            errors: getDefaultErrors(),
            cpt: null,
            cpn: null,
            rtt: null,
            recup: null,
            leavesAll: [],
            publicHolidays: [],
            hasRtts: false,
            selectedResource: null,
        };
    },
    computed: {
        // @ts-ignore
        ...mapGetters({
            user: "Resource/auth/user",
        }),
        selectedDaysNb() {
            let daysNb = this.newLeave.dates.length;
            if (this.newLeave.startHalf) daysNb -= .5;
            if (this.newLeave.endHalf) daysNb -= .5;
            if (!daysNb) return 1;
            return daysNb;
        },
        overrun() {
            if (!this.newLeave.issueId) return false;
            if ((this.newLeave.issueId === ISSUE_LEAVE_CP || this.newLeave.issueId === ISSUE_LEAVE_ANCIEN) && this.selectedDaysNb > this.cp) return true;
            if (this.newLeave.issueId === ISSUE_LEAVE_RTT && this.selectedDaysNb > this.rtt) return true;
            if (this.newLeave.issueId === ISSUE_LEAVE_RECUP && this.selectedDaysNb > this.recup) return true;
            return false;
        },
        remainingParts() {
            const remainingParts = [];
            if (this.cpt) remainingParts.push({ type: 'cpt', value: this.cpt, label: 'congés' });
            if (this.hasRtts) remainingParts.push({ type: 'rtt', value: this.rtt, label: 'RTT' });
            if (this.recup) remainingParts.push({ type: 'recup', value: this.recup, label: 'récupération' });
            return remainingParts;
        },
        remainingText() {
            if (!this.remainingParts.length) {
                return '<div class="text-center">Pour connaître vos jours disponibles,<br />merci de vous référer à votre dernier bulletin de paie</div>';
            }
            const who = this.thatsMe ? 'vous' : 'lui';
            return `Il ${who} reste&nbsp;: ` + this.remainingParts.map(part => {
                let plus = '';
                let label = part.label;
                if (part.type === 'cpt' && parseFloat(this.cpn)) {
                    plus = (this.remainingParts.length > 1 ? '<br />' : ' ');
                    plus += 'dont ' + parseFloat(this.cpn) + ' avant juin';
                }
                if (part.type === 'rtt') {
                    label = `<abbr class="rtt_notice">${label}</abbr>`;
                    if (parseFloat(this.rtt)) {
                        plus = '<br />avant fin déc.';
                    }
                }
                return `<div data-leave-type="${part.type}"><span><b>${parseFloat(part.value)}</b> jour${part.value > 1 ? 's' : ''}</span> de ${label}${plus}</div>`;
            }).join('');
        },
        canAddForOthers() {
            return userIsAtLeastDP(this.user);
        },
        thatsMe() {
            return this.selectedResource === this.user;
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        showDays(hours) {
            return Helpers.showDays(hours);
        },
        showDate(date) {
            return dayjs(date).format('ddd D MMM');
        },
        leavesForStatus(status) {
            return this.leavesAll.filter((leave) => leave.status === status);
        },
        changedIssue() {
            this.errors.issueId = false;
        },
        selectType(type) {
            if (!type) return;
            if (type === 'cpt') this.newLeave.issueId = ISSUE_LEAVE_CP;
            if (type === 'rtt') this.newLeave.issueId = ISSUE_LEAVE_RTT;
            if (type === 'recup') this.newLeave.issueId = ISSUE_LEAVE_RECUP;
        },
        selectRemaining(e) {
            const type = e.target.closest('[data-leave-type]');
            if (!type) return;
            this.selectType(type.dataset.leaveType);
        },
        updateRange(selectedRange) {
            this.newLeave.dates = [];
            this.newLeave.startHalf = false;
            this.newLeave.endHalf = false;
            this.errors.dates = false;
            if (selectedRange.length !== 2) return;
            const skipDates = this.publicHolidays.concat(this.leavesAll.filter(l => l.hours > 4).map(l => l.spent_on));
            this.newLeave.dates = Helpers.getBusinessDates(selectedRange[0], selectedRange[1], skipDates);
        },
        validateNewLeaves() {
            if (this.overrun) return false;
            this.errors.issueId = !this.newLeave.issueId;
            this.errors.dates = !this.newLeave.dates.length;
            return !Object.values(this.errors).find(error => error);
        },
        createLeaves() {
            this.errors = getDefaultErrors();
            if (!this.validateNewLeaves()) return;
            const leave = this.newLeave;
            const input: Partial<TimeEntryInput> = {
                issue: { id: leave.issueId },
                comment: leave.comments,
                user: { id: this.selectedResource.id },
                project: { id: ADMINISTRATIVE_PROJECT },
                activityId: ACTIVITY_CONSULTANT,
                multiple: leave.dates.map((spent_on, i) => ({
                    spent_on,
                    hours: Helpers.leaveHoursForIndex(leave, i),
                })),
            };
            this.$store.dispatch('Crud/upsertTimeEntry', input).then(() => {
                this.$gtag.event('Create Leaves', {
                    event_category: 'Leave Modal',
                    value: this.selectedDaysNb,
                });
                this.newLeave = getDefaultNewLeave();
                this.$refs.newLeaveDatePicker.fp.clear();
                this.submitted();
            });
        },
        submitted() {
            this.refresh();
            if (!this.thatsMe) return;
            this.$store.dispatch('Dashboard/list/heartbeat');
            this.$emit('submitted');
        },
        refreshRemaining() {
            this.$store.dispatch('Dashboard/list/getLeaveCounters', this.selectedResource.id).then((leaveCounters) => {
                if (!leaveCounters.length) return;
                const remainings = Helpers.getRemainingLeaves(leaveCounters[0]);
                this.cpt = Math.round((remainings.cp + remainings.cpn) * 10) / 10;
                this.cpn = Math.round(remainings.cpn * 10) / 10;
                this.rtt = Math.round(remainings.rtt * 10) / 10;
                this.recup = Math.round(remainings.recup * 10) / 10;
                this.hasRtts = leaveCounters[0].leaves_rtt !== null;
            });
        },
        refreshLeaves() {
            const payload: Record<string, string> = { mode: 'all' };
            if (!this.thatsMe) payload.userId = this.selectedResource.id
            this.$store.dispatch('Dashboard/list/getLeaves', payload).then(data => {
                this.leavesAll = data;
                this.$refs.newLeaveDatePicker.fp.redraw();
            });
        },
        refresh() {
            if (!this.selectedResource) this.selectedResource = this.user;
            this.refreshRemaining();
            this.refreshLeaves();
        },
        onDayCreate(dObj, dStr, fp, dayElem) {
            const date = dayElem.dateObj;
            const dateFormatted = dayjs(date).format(DATE_FORMAT);
            const dayOff = date.getDay() === 0 || date.getDay() === 6
                || this.publicHolidays.indexOf(dateFormatted) !== -1;
            const leaveHours = this.leavesAll.filter(l => l.spent_on === dateFormatted).reduce((ac, c) => ac + c.hours, 0);
            if (dayOff || leaveHours > 4) dayElem.classList.add('flatpickr-disabled');
            if (!leaveHours) return;
            const eventWidth = Math.min(leaveHours, 8) / 8 * 32;
            dayElem.innerHTML += `<span class="flatpickr_event" style="width: ${eventWidth}px; left: calc(50% - ${eventWidth/2}px);" />`;
        },
    },
    mounted() {
        this.refresh();
        const from = dayjs().subtract(200, 'day').format(DATE_FORMAT);
        const to = dayjs().add(200, 'day').format(DATE_FORMAT);
        this.$store.dispatch('TimeEntry/list/getPublicHolidays', { from, to }).then(data => {
            this.publicHolidays = data.public_holidays.filter(entry => entry.country === this.selectedResource.country).map(entry => entry.date);
        });
        this.$store.dispatch('Issue/list/getList', { projectId: ADMINISTRATIVE_PROJECT }).then(data => {
            this.leaveTypes = data;
        });
        tippyDelegate(this.$refs.remainingLeaves, {
            target: '.rtt_notice',
            content: 'RTT mis à jour aux<br/><b>1er janv</b> et <b>1er juillet</b><br/>de chaque année',
            placement: 'right',
        });
        this.selectType(this.type);
    },
};
