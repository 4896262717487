










export default {
    props: {
        bigNumber: {
            required: true,
            type: Number,
        },
    },
}
