const mapping = {
    creditVsVcr: {
        title: 'Crédits vs Versions/Crédits',
        fields: [
            {
                key: 'customer_name',
                name: 'Client',
            },
            {
                key: 'opportunity_name',
                name: 'Opportunité',
            },
            {
                key: 'profile_name',
                name: 'Profil',
            },
            {
                key: 'crd_days',
                name: 'Crédit (j.)',
                type: 'number',
            },
            {
                key: 'vcr_days',
                name: 'VCR (j.)',
                type: 'number',
            },
            {
                key: 'balance',
                name: 'Diff',
                type: 'number',
            },
        ],
        ignorable: true,
    },
    vcrVsSold: {
        title: 'Versions/Credits vs vendu',
        fields: [
            {
                key: 'project_name',
                name: 'Projet',
            },
            {
                key: 'version_name',
                name: 'Version',
            },
            {
                key: 'vcr_days',
                name: 'VCR (j.)',
                type: 'number',
            },
            {
                key: 'sold_days',
                name: 'Vendu (j.)',
                type: 'number',
            },
            {
                key: 'diff_abs',
                name: 'Diff',
                type: 'number',
            },
        ],
    },
    profileMismatch: {
        title: 'Saisies avec profil incohérent',
        filterable: true,
        fields: [
            {
                key: 'pro_name',
                name: 'Projet',
            },
            {
                key: 'tie_date',
                name: 'Date',
            },
            {
                key: 'tie_resource',
                name: 'Ressource',
            },
            {
                key: 'iss_name',
                name: 'Tâche',
            },
            {
                key: 'iss_version',
                name: 'Version Tâche',
            },
            {
                key: 'tie_version',
                name: 'Version saisie',
            },
            {
                key: 'tie_profile',
                name: 'Profil saisi',
            },
        ],
    },
    futurePassed: {
        title: 'Saisies de temps planifié dans le passé',
        fields: [
            {
                key: 'pro_name',
                name: 'Projet',
            },
            {
                key: 'iss_name',
                name: 'Tâche',
            },
            {
                key: 'tie_date',
                name: 'Date',
                link: 'timeEntry',
            },
        ],
    },
    passedIncomplete: {
        title: 'Tâches avec temps réalisé présentant des anomalies',
        filterable: true,
        fields: [
            {
                key: 'pro_name',
                name: 'Projet',
            },
            {
                key: 'iss_name',
                name: 'Tâche',
                link: 'issue',
            },
            {
                key: 'problem',
                name: 'Anomalie',
                tooltip: {
                    'En souffrance': '<div>Progression inférieure à 100%</div><div>Pas de temps planifié</div><div>Version de type "Forfait"</div><div>Workitem de type "Task"</div>',
                },
            },
        ],
    },
};

export {
    mapping,
}
