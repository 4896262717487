import dayjs from 'dayjs';
import { mapGetters } from "vuex";
import * as Helpers from "../dashboard-helpers";
import Leave from "./Leave.vue";

const LEAVE_INDEXES = {
    issue: {
        id: 'issue_id',
        label: 'subject',
    },
    user: {
        id: 'user_id',
        // @ts-ignore
        label(leave, { indexedResources }) {
            return indexedResources.get(parseInt(leave.user_id));
        },
    },
};

export default {
    name: "LeaveModalBlock",
    components: { Leave },
    props: {
        leaves: Array,
        status: Number,
        submittable: {
            type: Boolean,
            default: true,
        },
        indexBy: {
            type: String,
            default: 'issue',
            validator(value) {
                return Object.keys(LEAVE_INDEXES).indexOf(value) !== -1;
            },
        },
    },
    data() {
        return {
            selectedLeaves: [],
            selectedAll: false,
        };
    },
    computed: {
        // @ts-ignore
        ...mapGetters({
            resources: "Resource/list/resourcesAll",
        }),
        selectedDaysNb() {
            return Helpers.showDays(this.selectedLeaves.reduce(Helpers.leaveReducer, 0));
        },
        indexedResources() {
            return new Map(this.resources.map(res => [res.id, `${res.firstname} ${res.lastname}`]));
        },
        indexedLeaves() {
            const indexId = LEAVE_INDEXES[this.indexBy].id;
            const indexLabel = LEAVE_INDEXES[this.indexBy].label;
            const indexedResources = this.indexedResources;
            return Object.values(this.leaves.reduce((indexedLeaves, leave) => {
                const id = leave[indexId];
                if (!indexedLeaves[id]) {
                    const label =
                        typeof indexLabel === 'function'
                            ? indexLabel(leave, { indexedResources })
                            : leave[indexLabel];
                    indexedLeaves[id] = { id, label, leaves: [] };
                }
                indexedLeaves[id].leaves.push(leave);
                return indexedLeaves;
            }, {})).sort((a: any, b: any) => {
                if (a.label > b.label) return 1;
                if (a.label < b.label) return -1;
                return 0;
            });
        },
        issueResourceLeaves() {
            return Helpers.indexLeavesByIssueResource(this.leaves, this.indexedResources);
        },
    },
    methods: {
        showDays(hours) {
            return Helpers.showDays(hours);
        },
        showDate(date) {
            return dayjs(date).format('ddd D MMM');
        },
        toggleAll() {
            if (this.selectedAll) {
                this.selectedLeaves = this.selectedLeaves
                    .concat(this.leaves)
                    .filter((v, i, a) => a.indexOf(v) === i);
            } else {
                this.selectedLeaves = this.selectedLeaves.filter(
                    leave => !this.leaves.includes(leave)
                );
            }
        },
        submitLeaves() {
            this.updateLeaves(this.status + 1);
        },
        unsubmitLeaves() {
            if (this.status === 0) return this.deleteLeaves();
            this.updateLeaves(this.status - 1);
        },
        updateLeaves(status) {
            if (!this.selectedLeaves.length) return;
            this.$store.dispatch('Dashboard/list/setLeavesStatus', {
                leaves: this.selectedLeaves.map(leave => leave.id),
                status
            }).then(() => {
                this.$gtag.event('Update Leaves to level ' + this.status, {
                    event_category: 'Leave Modal',
                    value: this.selectedDaysNb,
                });
                this.submitted();
            });
        },
        deleteLeaves() {
            if (!this.selectedLeaves.length) return;
            if (!window.confirm('Êtes-vous sûr de vouloir supprimer les congés sélectionnés ?')) return;
            const timeEntries = this.selectedLeaves.map(leave => ({ id: leave.id }));
            this.$store.dispatch('Crud/removeTimeEntry', { timeEntries }).then(() => {
                this.$gtag.event('Delete Leaves', {
                    event_category: 'Leave Modal',
                    value: this.selectedDaysNb,
                });
                this.submitted();
            });
        },
        submitted() {
            this.selectedLeaves = [];
            this.selectedAll = false;
            this.$emit('submitted');
        },
    },
};
